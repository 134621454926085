import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { formatDate } from "utils/misc";
import { RowSelectCell } from "hooks/useRowSelectNew";
import ClaimOrder from "components/order-status/claim-order";
import PendingInfo from "components/order-status/pending-info";
import MdPriorityHigh from "react-icons/lib/md/priority-high";
import { OrdersTableSettingsContext } from "./use-table";

const OrderTableRow = (row) => {
  const { visibleColumnFields, tableSettings } = useContext(OrdersTableSettingsContext);

  function checkColumn(field) {
    if (tableSettings.hasOwnProperty(field)) {
      return tableSettings[field].active || false;
    }
    else {
      return visibleColumnFields.includes(field);
    }
    
  }

  return (
    <tr>
      {checkColumn("all") && <td><RowSelectCell id={row.id} /></td>}
      {checkColumn("claimed_by") && <td><ClaimOrder claimed_by={row.claimed_by} id={row.id} /></td>}
      {checkColumn("invoice_number") && (
        <td>
          <Link to={`/orders/${row.id}`}>{row.invoice_number}</Link>
        </td>
      )}
      {checkColumn("patient") && (
        <td>
          <Link to={`/patients/${row.patient_id}`}>{row.patient}</Link>
        </td>
      )}
      {checkColumn("patient_account_number") && <td>{row.patient_account_number}</td>}
      {checkColumn("company") && <td>{row.company}</td>}
      {checkColumn("status") && <td>{row.status}</td>}
      {checkColumn("submitted") && <td>{formatDate(row.submitted)}</td>}
      {checkColumn("status_changed") && <td>{formatDate(row.status_changed)}</td>}
      {checkColumn("hold_count_total") && (
        <td>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            {row.hold_count_closed}/{row.hold_count_total}
            {row.hold_count_open > 1 && <MdPriorityHigh size="16px" color="red" />}
          </div>
        </td>
      )}
      {checkColumn("last_updated") && <td>{formatDate(row.last_updated)}</td>}
      {checkColumn("pending_delayed_date") && <td>{formatDate(row.pending_delayed_date)}</td>}
      {checkColumn("entered_by") && <td>{row.entered_by}</td>}
      {checkColumn("sleep_coach_assignment") && <td>{row.sleep_coach_assignment}</td>}
      {checkColumn("team_name") && <td>{row.team_name}</td>}
      {checkColumn("last_order_date") && <td>{formatDate(row.last_order_date)}</td>}
      {checkColumn("platform") && <td>{row.platform}</td>}
      {checkColumn("insurance") && <td>{row.insurance}</td>}
      {checkColumn("high_priority") && (
        <td>
          {row.high_priority ? (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <MdPriorityHigh size="16px" color="red" />
            </div>
          ) : null}
        </td>
      )}
      {checkColumn("onHoldReason") && <td>{row.onHoldReason}</td>}
      {checkColumn("reason") && (
        <td>
          <div style={{ width: 200 }}>{row.reason}</div>
        </td>
      )}
      {checkColumn("pending_info") && (
        <td>
          <PendingInfo orderId={row.id} value={row.pending_info} />
        </td>
      )}
    </tr>
  );
};

OrderTableRow.propTypes = {
  row: PropTypes.object.isRequired,
};

export default OrderTableRow;
